import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styled from "styled-components"

const Container = Styled.div`
  margin-top: 86px;
  p {
    margin-bottom: 0;
  }
  
  @media all and (min-width: 992px) {
    p {
      white-space: pre-wrap;
    }
    margin-top: 60px;
    padding: 50px 75px;
  }
`

const Highlighted = Styled.p`
  color: ${props => props.theme.headerText};
  margin-bottom: 13px !important;
`

const title = "About us"

const About = () => {
  return (
    <Layout activeMenu={title}>
      <SEO title="About Us" />
      <Container>
        <p>
          Born in another time and place, they might have become accomplished
          thespians at London’s West End or in Hollywood. The chance that makes
          them what and where they are today is one that has pleased a number of
          luxury brands and their audiences.
          <br />
          <br />
          Introducing Sambal Lab, the reputed playwrights of New Consumerism.
          Approaching every project with an intimate study of brand and
          consumer, Sambal Lab script a strategy from beginning to end before
          crafting values, themes and trends into highly engaging, authentic
          consumer experiences.
          <br />
          <br />
          With past and current clients the likes of Guinness, Hennessy, Tetley
          and Jameson—no matter how elaborate or private the brand
          experience—the response and the returns on investment have been
          equally dramatic.
          <br />
          <br />
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html:
              "Every brand has stories to tell. Few can bring them to life.\nEven fewer garner critical response.",
          }}
        />
        <Highlighted>
          <br />
          Sambal Lab bring you Visible Brand Actions™
        </Highlighted>
      </Container>
    </Layout>
  )
}

export default About
